import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
  path: "/article",
  redirect: "/article/index",
  component: MidRouterView,
  children: [
    {
      path: "list",
      name: "articleList",
      meta: {
        title: "列表 demo",
        hideHeader: false,
        // hideNavLeftBtn: true
        navRight: 'ListNavRight'
      },
      component: () => import("@/views/article/list.vue"),
    },
  ],
};

export default routes;
