<template>
  <svg aria-hidden="true" class="svg-icon" :class="classes">
    <use :href="symbolId" :fill="color" />
  </svg>
</template>

<script>
import { defineComponent, computed } from "vue";
import conf from '@/config'

export default defineComponent({
  name: "SvgIcon",
  props: {
    prefix: {
      type: String,
      default: conf.svgIconPrefix,
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "#333",
    },
    classes: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  setup(props) {
    const symbolId = computed(() => `#${props.prefix}-${props.name}`);
    return { symbolId };
  },
});
</script>
<style scoped>
.svg-icon {
  display: block;
  line-height: inherit;
}
</style>