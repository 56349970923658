/**
 * maybe js工具函数库
 * @author maybe
 * @license https://gitee.com/null_639_5368/js-utils
 */
import Cookies from 'js-cookie'

/**
 * 元素是否在视窗内
 * @param {*} el
 */
export function isInViewPort(el: HTMLElement): boolean {
  //获取屏幕高度
  let windowTop =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  // 获取元素相对视窗的位置
  const { top, bottom } = el.getBoundingClientRect();
  if (bottom > 0 && top < windowTop) {
    // 已经进入可视区
    console.log("已经进入可视区");
    return true;
  } else {
    // 未进入可视区
    console.log("未进入可视区");
    return false;
  }
}
/**
 * 函数节流
 * @param {*} fn
 * @param {*} interval
 * @param {*} isImmediate
 */
export function throttle(
  fn: Function,
  wait = 500,
  isImmediate = false
): Function {
  let flag = true;
  let timer = null as any;
  if (isImmediate) {
    return function (this: any) {
      if (flag) {
        fn.apply(this, arguments);
        flag = false;
        timer = setTimeout(() => {
          flag = true;
        }, wait);
      }
    };
  }
  return function (this: any) {
    if (flag) {
      flag = false;
      let timer = setTimeout((...rest: any) => {
        fn.apply(this, rest);
        flag = true;
      }, wait);
    }
  };
}
/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export function debounce(
  func: Function,
  wait: number,
  immediate: boolean
): Function {
  let timer: any;
  return function (this: any) {
    let context = this,
      args = arguments;

    if (timer) clearTimeout(timer);
    if (immediate) {
      let callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, wait);
      if (callNow) func.apply(context, args);
    } else {
      timer = setTimeout(() => {
        func.apply;
      }, wait);
    }
  };
}

/**
 * 深拷贝
 * @param {*} obj
 */
export function deepClone(obj: any): [] | Object {
  let result: any = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object") {
        result[key] = deepClone(obj[key]); //递归复制
      } else {
        result[key] = obj[key];
      }
    }
  }
  return result;
}
/**
* 动态创建script
* @param {string} src 脚本地址 
* @param {string} id 唯一标识
* @param {'defer' | 'async'} load 'defer' | 'async'  加载脚本的时机,默认是同步加载 
* @example 
* createScript(...).then().catch() 
* ----or----
* try {await  createScript(...)} catch(err){console.log(err)}
* @returns {Promise<any>} Promise
*/
export function createScript(src: string, id?: string, load?: 'defer' | 'async'): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement("script");  //创建一个script标签
      script.type = "text/javascript";
      script.src = src;
      if (id) script.id = id;
      if (load) {
        script.setAttribute('load', load);
      }
      document.getElementsByTagName('head')[0].appendChild(script);
      console.log(`script id：${id} 加载中...`)
      script.onload = function () {
        console.log(`script id：${id} 加载完成`)
        resolve(id);
      }
    } catch (error) {
      reject(error);
    }
  })
}
/**
* 删除脚本
* @param {string} id script id  
* @returns {Promise<any>} Promise
*/
export function removeScript(id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    try {
      if (!id) {
        throw new Error('请提供script id名称')
      }
      const dom = document.getElementById(id);
      if (dom) {
        document.getElementsByTagName('head')[0].removeChild(dom);
        console.log(`删除script id：${id}成功！`)
      }
      resolve(id);
    } catch (error) {
      reject(error);
    }
  })
}

/**
 * 兼容存储Storage
 * @description 优先级 1.localStorage 2.cookie
 * @param key 获取的key值
 * @param content
 */
export const setStore = (key: string, content: any): void => {
  if (!key) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  if (typeof window.localStorage == 'object') {
    window.localStorage.setItem(key, content);
  } else {
    Cookies.set(key, content)
  }
};
/**
 * 兼容获取Storage
 * @description  优先级 1.localStorage 2.cookie
 * @param key 获取的key值
 * @returns {string}
 */
export const getStore = (key: string) => {
  if (!key) return;
  if (typeof window.localStorage == 'object') {
    const strObj = window.localStorage.getItem(key);
    if (strObj) {
      try {
        return JSON.parse(strObj)
      } catch (error) {
        return strObj
      }
    }
    return null;
  } else {
    const strObj = Cookies.get(key);
    if (strObj) {
      try {
        return JSON.parse(strObj)
      } catch (error) {
        return strObj
      }
    }
    return null;
  }
};
/**
 * 兼容删除Storage
 * @description 优先级 1.localStorage 2.cookie
 * @param key 获取的key值
 */
export const removeStore = (key: string): void => {
  if (!key) return;
  if (typeof window.localStorage == 'object') {
    window.localStorage.removeItem(key);
  } else {
    return Cookies.remove(key)
  }
};

/**
 * 延迟加载方法
 * @param {Function} fn
 * @param {number} time
 */
export const submitTimeOut = (fn: Function, time: number): void => {
  setTimeout(function () {
    fn();
  }, time);
};
/**
 * 数组插入到Formdata
 * @example 1.在上传多张图片的时候会用到
 * @description 此方法会改变原数组,console.log(formData)是看不到效果的必须在请求体里面才能看到
 * @param {FormData} formData 源formdata
 * @param {string} key 数组key值
 * @param {Array} arr 数组
 * @returns {void}
 */
export const formatArrToFormData = (
  formData: FormData,
  key: string,
  arr: Array<any>
): void => {
  arr.forEach((file, index) => {
    formData.append(`${key}[${index}]`, file);
  });
};

/**
 * 判断是否移动设备访问
 */
export function isMobileUserAgent() {
  return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile/i.test(
    window.navigator.userAgent.toLowerCase()
  );
}

/**
 * 获取页面高度
 */
export function getPageHeight() {
  var g = document,
    a = g.body,
    f = g.documentElement,
    d = g.compatMode == "BackCompat" ? a : g.documentElement;
  return Math.max(f.scrollHeight, a.scrollHeight, d.clientHeight);
}
/**
 * 获取页面宽度
 */
export function getPageWidth() {
  var g = document,
    a = g.body,
    f = g.documentElement,
    d = g.compatMode == "BackCompat" ? a : g.documentElement;
  return Math.max(f.scrollWidth, a.scrollWidth, d.clientWidth);
}

/**
 * 判断是否是微信内置浏览器环境
 */
export const isWeixin = () => {
  const ua = navigator.userAgent.toLowerCase();
  const uaMatch: any = ua.match(/MicroMessenger/i);
  if (uaMatch == "micromessenger") {
    return true;
  } else {
    return false;
  }
};
/**
 * 判断运行环境是安卓还是IOS
 * @returns  {boolean} true => 安卓 false => IOS
 */
export function isAndroid() {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
  // let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  return isAndroid ? true : false;
}

/**
 * 判断运行环境是否是IOS
 */
export const isIos = () => {
  let u = navigator.userAgent;
  let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端\
  return iOs;
}

/**
 * 判断是否在元素外触发事件
 * @param event  事件源
 * @param el 元素
 * @returns {*} true 在元素外 false 在元素内
 */
export function isOutEl(event: any, el: HTMLElement) {
  const { height, width, x, y } = el.getBoundingClientRect();
  const { clientX, clientY } = event;
  if (
    clientY > y + height ||
    clientY < y ||
    clientX < x ||
    clientX > x + width
  ) {
    return true;
  }
  return false;
}

/**
 * 获取事件冒泡路径
 * @description 兼容ie11,edge,chrome,firefox,safari
 * @param evt
 * @returns {*}
 */
export function getEventPath(evt: any) {
  const path = (evt.composedPath && evt.composedPath()) || evt.path,
    target = evt.target;
  if (path != null) {
    return path.indexOf(window) < 0 ? path.concat(window) : path;
  }
  if (target === window) {
    return [window];
  }
  const getParents = (node: Node, memo?: any): any => {
    memo = memo || [];
    const parentNode = node.parentNode;

    if (!parentNode) {
      return memo;
    } else {
      return getParents(parentNode, memo.concat(parentNode));
    }
  };
  return [target].concat(getParents(target), window);
}
/**
 * 判断对象是否为空对象({})
 * @param obj  事件源
 */
export function isEmptyObj(obj: object) {
  return Object.keys(obj).length <= 0;
}
/**
 * 判断传入的值是否为空 支持 string,object,array
 * @param obj 
 * @returns {boolean} '',null,[],{},undefined = true
 */
export function isEmpty(obj: string | object | []) {
  if (typeof obj == 'object' && (!obj || Object.keys(obj).length == 0)) {
    return true;
  } else if (!obj) {
    return true;
  }
  return false;
}
/**
 * 生成http-get查询字符串
 * @param url 
 * @param data 
 */
export function createQueryStr(url: string, data: any) {
  if (typeof (url) == 'undefined' || url == null || url == '') {
    return '';
  }
  if (typeof (data) == 'undefined' || data == null || typeof (data) != 'object') {
    return '';
  }
  if (Object.keys(data).length == 0) {
    return url;
  }
  url += (url.indexOf("?") != -1) ? "" : "?";
  for (let k in data) {
    url += ((url.indexOf("=") != -1) ? "&" : "") + k + "=" + encodeURI(data[k]);
  }
  return url;
}
/**
 * 分割数组subGroupLength份
 * @param array 
 * @param subGroupLength 
 */
export function groupArray(array: any[], subGroupLength: number) {
  let index = 0;
  let newArray = [] as any[];
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)));
  }
  return newArray;
}
/**
 * blob转base64
 * @param blob 
 * @param cb 
 */
export function blobToBase64(blob: Blob, cb: (base64: string) => void) {
  let reader = new FileReader();
  reader.onload = function (evt: any) {
    const base64 = evt.target.result;
    cb(base64);
  };
  reader.readAsDataURL(blob);
}
/**
 * 获取host之后的路径
 * @returns {string}  
 */
export function getUrlRelativePath() {
  var url = window.location.href;
  var arrUrl = url.split("//");
  var start = arrUrl[1].indexOf("/");
  var relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符
  return relUrl;
}

/**
 * 日期时间格式化
 * @param fmt
 * @param date
 */
export function dateFormat(fmt: string, date: any) {
  let ret;
  const opt: any = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}


/**
 * 终止Promise链
 * @description 返回一个pending 状态或reject状态的Promise对象即可终止Promise链
 * @param func 终止前调用的函数
 * @returns {Promise<unknown>} Promise<unknown>
 */
export const breakPromise = (func: Function) => {
  return new Promise(() => {
    func();
  })
}

export const setSessionStore = (key: string, val: any) => {
  return window.sessionStorage.setItem(key, val);
}

export const getSessionStore = (key: string) => {
  return window.sessionStorage.getItem(key);
}

export const removeSessionStore = (key: string) => {
  return window.sessionStorage.removeItem(key);
}


/**
 * 将url（url参数）转化为对象
 * @param url 
 * @returns 
 */
export function getUrlObject(url: string): { url: string, query: object } {
  const index = url.indexOf('?');
  const obj: any = {};
  if (index === -1) return obj;
  const queryStr = url.slice(index + 1);
  const arr = queryStr.split('&');
  for (const item of arr) {
    const keyValue = item.split('=');
    obj[keyValue[0]] = keyValue[1]
  }
  const host = url.includes('?') ? url.split('?')[0] : url;
  return {
    url: host,
    query: obj,
  };
}


/**
 * 使用<a>标签下载文件
 * @param url 下载地址
 */
export const download = (url: string) => {
  if (!url) {
    return;
  }
  var aLink = document.createElement('a')
  aLink.style.display = 'none'
  aLink.href = url

  document.body.appendChild(aLink)
  aLink.click()
  document.body.removeChild(aLink) //下载完成移除元素
}