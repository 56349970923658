import { App } from 'vue';


// https://github.com/youzan/vant/issues/8302
type EventShim = {
  new(...args: any[]): {
    $props: {
      onClick?: (...args: any[]) => void;
    };
  };
};

export type WithInstall<T> = T & {
  install(app: App): void;
} & EventShim;

// using any here because tsc will generate some weird results when using generics
export function withInstall<T>(options: any): WithInstall<T> {
  (options as Record<string, unknown>).install = (app: App) => {
    const { name } = options as any;
    app.component(name, options);
  };

  return options;
}
