import store from "@/store";
import { useUserStore } from "@/store/modules/user";
import { App } from "vue";

const bindAuth = (el: HTMLElement, binding: any, vnode: any) => {
    if (binding.value == undefined) return;
    const userStore = useUserStore();
    // 获取当前用户信息
    const userinfo = userStore.userinfo;

    // console.log(userinfo)
    // 获取可访问权限组
    let value = binding.value;
    // 是否有权限访问
    let auth = false;

    if (Array.isArray(value)) {
        auth = value.includes(userinfo.type);
    } else {
        auth = value == userinfo.type
    }

    if (!auth) {
        //移除元素
        el.remove();
    }
}

export default {
    install(app: App) {
        /**
         * 根据用户权限控制元素显示或隐藏
         * @example <div v-auth="[$Enum.auth.vip,$Enum.auth.superVip]"></div>
         */
        app.directive('auth', {
            updated(el, binding, vnode) {
                bindAuth(el, binding, vnode)
            },
            // 当被绑定的元素插入到 DOM 中时……
            mounted(el, binding, vnode) {
                bindAuth(el, binding, vnode)
            }
        })
    }
}