import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/package",
    redirect: "/package/index",
    component: MidRouterView,
    children: [
        {
            path: "index",
            name: "index",
            meta: {
                title: "我的套餐",
                hideHeaderNav: true
            },
            component: () => import("@/views/package/index.vue"),
        },
        {
            path: "pay",
            name: "pay",
            meta: {
                title: "充值",
                hideHeaderNav: true
            },
            component: () => import("@/views/package/pay.vue"),
        },
    ],
};

export default routes;
