<template>
  <div class="vp-container">
    <van-nav-bar v-if="!hideNav" :title="title" :left-arrow="showNavLeft" :left-text="leftText" :fixed="fixed"
      :safe-area-inset-top="safeAreaInsetTop" v-bind="mergeProps" @click-left="onClickLeft">
      <template #left v-if="$slots.headLeft && showNavLeft">
        <slot name="headLeft"></slot>
      </template>
      <template #right>
        <slot name="headRight"></slot>
      </template>
    </van-nav-bar>
    <div class="vp-container__content" :style="{ background }" :class="[noContentPadding && 'clearPadding']">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts" name="VpContainer">
import { computed, useAttrs } from "vue";

import { NavBar as VanNavBar } from "vant";


const props = defineProps({
  title: {
    type: String,
    default: "title",
  },
  background: {
    type: [String],
  },
  leftText: {
    type: String,
    default: "",
  },
  fixed: {
    type: Boolean,
    default: false,
  },
  hideNav: {
    type: Boolean,
    default: false,
  },
  showNavLeft: {
    type: Boolean,
    default: true,
  },
  noContentPadding: {
    type: Boolean,
    default: false,
  },
  safeAreaInsetTop: {
    type: Boolean,
    default: true
  },
})
const emit = defineEmits(['clickLeft'])

const attrs = useAttrs();

const mergeProps = computed(() => {
  // return Object.assign({ ...attrs });
  return { ...attrs };
});


const onClickLeft = (e: Event) => {
  e.stopPropagation();
  emit("clickLeft");
};
</script>

