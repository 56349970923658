<template>
  <div class="list-head-right">
    <a @click="onClick">详情</a>
  </div>
</template>

<script lang="ts">
import { Toast } from "vant";
import { defineComponent, reactive, toRefs, ref } from "vue";

export default defineComponent({
  name: "ListNavRight",
  setup() {
    const state = reactive({});

    const onClick = () => {
      Toast.success("点击了详情");
    };
    return {
      ...toRefs(state),
      onClick,
    };
  },
});
</script>