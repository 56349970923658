import http from "@/utils/request";
import { HttpResultJson } from "@/types/http";

export interface UserInfo {
  id: number;
  name: string;
  avatar: string;
}

export function info(params?: any) {
  return http.request<HttpResultJson<any>>({
    url: "/me",
    method: "get",
    params,
  });
}

export function login(data: any) {
  return http.request<HttpResultJson>({
    url: "/auth/login",
    method: "post",
    data,
  });
}

export function logout() {
  return http.request<HttpResultJson>({
    url: "/user/logout",
    method: "post",
  });
}


export function register(data: any) {
  return http.request<HttpResultJson>({
    url: "/auth/register",
    method: "post",
    data
  });
}

export function resetPwd(data: any) {
  return http.request<HttpResultJson>({
    url: "/auth/resetPassword",
    method: "post",
    data
  });
}

// 关于本机
export function studentCardAbout(params?: any) {
  return http.request<HttpResultJson<UserInfo>>({
    url: "/studentcard/about",
    method: "get",
    params,
  });
}




// 刷新token
export function refreshToken(data?: any) {
  return http.request<HttpResultJson<any>>({
    url: "/auth/refreshToken",
    method: "post",
    data,
  });
}


export function completeInfo(data: any) {
  return http.request<HttpResultJson>({
    url: "/user/completeInfo",
    method: "post",
    data
  });
}

export function menus(params?: any) {
  return http.request<HttpResultJson>({
    url: "/user/menus",
    method: "get",
    params
  });
}


