<template>
  <div class="container" :class="[className]" :style="{ width, height }" ref="lotContainer"></div>
</template>

<script lang="ts" setup name="VueLottie">
/**
 * VueLottie
 * @description  lottie-web动画库vue封装组件
 * @author maybe
 * @doc lottie-web https://github.com/airbnb/lottie-web
 */

import { AnimationItem } from 'lottie-web';
import { onMounted, ref } from 'vue';
import lottie from 'lottie-web'

const props = defineProps({
  className: String,
  options: {
    type: Object,
    required: true,
  },
  height: String,
  width: String,
})
const emit = defineEmits(['animCreated'])
const anim = ref(null as unknown as AnimationItem)
const lotContainer = ref<Element>()

onMounted(() => {
  const defaultOptions = {
    container: lotContainer.value, // 容器
    renderer: "svg",
    loop: true,
    autoplay: true,
  };
  anim.value = lottie.loadAnimation(
    Object.assign(defaultOptions, props.options) as any
  );
  emit("animCreated", anim.value);
})
</script>


<style scoped lang="scss">

</style>