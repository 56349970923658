<template>
    <div class="rail-nav-right">
        <a @click="onClick">添加</a>
    </div>
    <van-action-sheet v-model:show="state.showAlert" :teleport="'body'" title="添加围栏">
        <div class="content">
            <vp-form @submit="onSubmit">
                <van-field v-model="state.form.name" placeholder="名称" label="名称" :rules="[
                    { required: true, message: '请输入名称' },
                
                ]"></van-field>
                <van-field v-model="state.selectItem.text" readonly clickable label=" 类型" placeholder="请选择类型" :rules="[
                    { required: true, message: '请选择类型' },
                
                ]" @click="state.showPicker = true"></van-field>
            </vp-form>
        </div>
    </van-action-sheet>
    <van-popup :teleport="'body'" v-model:show="state.showPicker" round position="bottom">
        <van-picker :columns="state.columns" @cancel="state.showPicker = false" @confirm="onTypeConfirm" />
    </van-popup>
</template>
  
<script lang="ts" setup name="RightNavRight">
import {
    defineComponent,
    reactive,
    onMounted,
    toRefs,
    nextTick,
    onUnmounted,
} from "vue";
import { Toast, ActionSheet as VanActionSheet } from "vant";
import { Form as VpForm } from '@/components/Vp'
import router from "@/router";

const state = reactive({
    showAlert: false,
    showPicker: false,
    actions: [],
    form: {
        name: '',
        type: ''
    },
    columns: [{
        type: 'circle',
        text: '圆形围栏',
    },
    {
        type: 'polygon',
        text: '多边形围栏',
    }],
    selectItem: {
        type: '',
        text: '',
    }
});



const onClick = async () => {
    // state.showAlert = true
    router.push({
        path: '/map/rail/show',
        query: {
            mode: 'add'
        }
    })
};

const onSubmit = () => {
    state.showAlert = false;
    router.push({
        path: '/map/rail/show',
        query: {
            mode: 'add',
            name: state.form.name,
            type: state.selectItem.type,
        }
    })
}
const onTypeConfirm = (item: any) => {
    state.showPicker = false;
    state.selectItem = item;
}

onMounted(() => {

});

onUnmounted(() => {

});
</script>
  
<style lang="scss" scoped>
.rail-nav-right {
    a {
        color: var(--van-custom-theme-color);
    }
}

.content {

    padding: 16px 16px 160px;
}
</style>
  