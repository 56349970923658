import router from "@/router";
import { getToken, setToken, getUtmSrc, setUtmSrc } from "@/utils/auth";
import {
  RouteLocationNormalized,
  NavigationGuardNext,
  Router,
} from "vue-router";
import store from "@/store/";
import { nextTick } from "process";
import { useUserStore } from "@/store/modules/user";
import { useConfigStore } from "@/store/modules/config";
import { useCommonStore } from "@/store/modules/common";
// 移动端控制台调试
import VConsole from "vconsole";
import { isIos } from "@/utils";
import { setIsHideFooterTabBar, setRedirect } from "@/utils/session";
import { bindSub } from "@/api/promotion";

export const createRouterGuards = (router: Router) => {
  const userStore = useUserStore();
  const configStore = useConfigStore();
  const commonStore = useCommonStore();
  // 检测是否初始化用户信息
  const checkHasUserInfo = async () => {
    let isInit = userStore.getIsInitUserInfo;
    if (!isInit) await userStore.initUserInfo();
  };

  /**
   * 路由拦截
   */
  router.beforeEach(
    async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      // 设置页面标题
      to.meta.title && (document.title = to.meta.title as string);
      to.query.title && (document.title = to.query.title as string);

      // 路由调试模式
      if (to.query.debug) {
        new VConsole();
        configStore.setDebug(true);
      }

      // 是否隐藏底部导航栏
      if (to.query.hasOwnProperty("hideFooterTabBar")) {
        setIsHideFooterTabBar(to.query.hideFooterTabBar);
      }

      // 修复ios环境中 vue-router history 模式获取微信sdk url的问题（vue history 模式）
      if (!commonStore.iosWxSdkUrl && isIos()) {
        commonStore.setIosWxSdkUrl(window.location.href);
      }

      // 获取 url token（可兼容三方平台对接）
      if (to.query.token) {
        // 设置token
        setToken(to.query.token as string);
        // 更新用户信息
        // await userStore.initUserInfo();
      }

      // 获取token
      const token = getToken();
      const utm_src = getUtmSrc();

      // 已登录
      if (token) {
        /**
         * 如果有token判断是否初始化用户信息
         * 用户信息建议每次打开网站后重新获取一次最新的用户信息，不建议存本地持久化缓存
         */
        // await checkHasUserInfo();

        // 静默绑定分销码用户的下级
        if (to.query.inviteCode) {
          await bindSub({
            inviteCode: to.query.inviteCode,
          });
        }

        if (to.path === "/login") {
          // 已经登录，跳转到首页
          next({ path: "/home" });
        } else {
          next();
        }
      } else {
        //  no token
        if (to.meta.open) {
          // 开放页面，无需验证
          next();
        } else if (to.meta.wxAuth) {
          // 设置跳转缓存
          setRedirect({
            type: "route",
            url: to.path,
            query: to.query,
            // lastRedirect: encodeURIComponent(`/personCenter`)
          });
          next(`/wxAuth`);
        } else {
          // 其他没有访问权限的页面被重定向到登录页面
          // next(`/login?redirect=${to.path}`);其他没有访问权限的页面被重定向到微信授权
          //
          let obj = {
            redirect: to.path,
            utm_src: utm_src,
          };
          let param = new URLSearchParams(obj).toString();
          next(`/login?${param}`);
        }
      }

      if (to.query.utm_src) {
        setUtmSrc(to.query.utm_src as string);
      }
    }
  );
};
