import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/contact",
    redirect: "/contact/index",
    component: MidRouterView,
    children: [
        {
            path: "index",
            name: "contactIndex",
            meta: {
                title: "联系人",
            },
            component: () => import("@/views/contact/index.vue"),
        },
        {
            path: "message",
            name: "contactMessage",
            meta: {
                title: "消息",
                hideHeaderNav: true
            },
            component: () => import("@/views/contact/message.vue"),
        },
    ],
};

export default routes;
