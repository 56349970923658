import { setStore, getStore, removeStore } from '@/utils'

// token key
const BASE_TOKEN = "BASE_TOKEN";
// 用户信息 key
const USER_INFO = "USER_INFO";
// 微信用户信息key
const WX_USER_INFO = "WX_USER_INFO";

const UTM_SRC = "UTM_SRC";


export function getToken() {
  return getStore(BASE_TOKEN);
}

export function setToken(token: string) {
  return setStore(BASE_TOKEN, token);
}

export function removeToken() {
  return removeStore(BASE_TOKEN);
}

export function getUserInfo() {
  return getStore(USER_INFO);
}

export function setUserInfo(userData: any) {
  return setStore(USER_INFO, userData);
}

export function removeUserInfo() {
  return removeStore(USER_INFO);
}

export function getWxUserInfo() {
  return getStore(WX_USER_INFO);
}

export function setWxUserInfo(data: any) {
  return setStore(WX_USER_INFO, data);
}

export function removeWxUserInfo() {
  return removeStore(WX_USER_INFO);
}

export function getUtmSrc() {
  return window.sessionStorage.getItem(UTM_SRC);
}

export function setUtmSrc(data: any) {
  return window.sessionStorage.setItem(UTM_SRC, data);
}

// export function removeUtmSrc() {
//   return window.sessionStorage.removeItem(UTM_SRC);
// }



