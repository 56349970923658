import { defineStore } from "pinia";



interface ICommonState {
    /**
     * ios 微信sdkurl
     */
    iosWxSdkUrl: string
}

export const useCommonStore = defineStore('common', {
    state: (): ICommonState => ({
        iosWxSdkUrl: ''
    }),
    actions: {
        setIosWxSdkUrl(val: string) {
            this.iosWxSdkUrl = val;
        },
    },
    getters: {
        getIosWxSdkUrl(): string {
            return this.iosWxSdkUrl;
        },
    }
})
