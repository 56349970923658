<template>
  <div class="vp-verify-button" :class="classes">
    <van-button class="vp-verify-button-btn" native-type="button" v-bind="mergeProps" :disabled="disabled"
      @click.stop="clickStart" :class="classes" :style="styles">{{
    verCodeText
      }}</van-button>
  </div>
</template>
<script >
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
} from "vue";

import { Button } from "vant";

export default defineComponent({
  name: "VpVerifyButton",
  components: {
    [Button.name]: Button,
  },
  props: {
    /**
     * cn: 倒计时长(s)
     * en: The countdown to long(s)
     */
    duration: {
      type: Number,
      default: 60,
    },
    classes: Array,
    styles: {
      type: Object,
      default: function () {
        return {
          width: "23vw",
        };
      },
    },
    text: {
      type: String,
      default: function () {
        return "发送验证码";
      },
    },
    suffix: {
      type: String,
      default: function () {
        return "秒";
      },
    },
  },
  setup(props, { emit, slots, attrs }) {
    const state = reactive({
      currentTime: 0,
      disabled: false,
      interval: null,
    });

    const mergeProps = computed(() => {
      return Object.assign({ size: "small", type: "primary" }, { ...attrs });
    });

    const verCodeText = computed(() => {
      return state.currentTime < props.duration
        ? `${state.currentTime}${props.suffix}`
        : props.text;
    });
    const reset = () => {
      clearInterval(state.interval);
      state.currentTime = props.duration;
      state.disabled = false;
    };
    const startCountdown = () => {
      if (state.disabled) return; // 正在倒计时中
      emit('start')
      state.disabled = true;
      state.currentTime--;
      state.interval = setInterval(() => {
        state.currentTime--;
        if (state.currentTime == 0) {
          clearInterval(state.interval);
          state.disabled = false;
          state.currentTime = props.duration;
          emit("finished");
        }
      }, 1000);
    };
    const start = () => {
      startCountdown();
    };

    const clickStart = () => {
      emit('onClick')
    }

    watch(
      () => props.duration,
      () => {
        reset();
      },
      {
        immediate: true,
      }
    );
    return {
      ...toRefs(state),
      start,
      reset,
      verCodeText,
      mergeProps,
      clickStart
    };
  },
});
</script>

<style lang="scss" scoped>
.vp-verify-button {
  :deep .van-button__text {
    font-size: 3vw;
  }
}
</style>
