<template>
  <div class="box">
    <router-view></router-view>
  </div>
</template>

<script lang="ts" setup name="App">
import { getUrlObject } from '@/utils'
import { onMounted } from "vue";
import { useRoute } from 'vue-router';

const route = useRoute();


onMounted(() => {

  // window.onpopstate = function (event: any) {
  //   console.log('history len: ', window.history.length)
  //   console.log("location: " + document.location + ", state: " + JSON.stringify(event.state));

  //   const uq = getUrlObject(event.forward);
  //   if (uq.lastRedirect) {
  //     window.history.pushState(null, '', uq.lastRedirect)
  //   }
  // };
})
</script>

<style lang="scss">
html,
body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: #f5f5f5;
}
</style>
