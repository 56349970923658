import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/chatAI",
    redirect: "/chatAI/index",
    component: MidRouterView,
    children: [
        {
            path: "index",
            name: "chatAIIndex",
            meta: {
                title: "AI群聊",
                background: '#fff',
                hideHeaderNav: true
            },
            component: () => import("@/views/chatAI/index.vue"),
        },
        {
            path: "session",
            name: "chatAISession",
            meta: {
                title: "AI群聊",
                hideHeaderNav: true
            },
            component: () => import("@/views/chatAI/session.vue"),
        },
        {
            path: "member",
            name: "chatAIMember",
            meta: {
                title: "AI群聊",
                hideHeaderNav: true
            },
            component: () => import("@/views/chatAI/member.vue"),
        },
    ],
};

export default routes;
