import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";
import { getUrlObject } from "@/utils";


const routes: RouteRecordRaw = {
    path: "/shop",
    redirect: "/shop/list",
    component: MidRouterView,
    children: [
        {
            path: "list",
            name: "shopList",
            meta: {
                title: "商城",
                hideHeaderNav: true,
                open: false,
                wxAuth: true
            },
            component: () => import("@/views/shop/list.vue"),
        },
        {
            path: "detail",
            name: "shopDetail",
            meta: {
                title: "商品详情",
                hideHeaderNav: true,
                open: false,
                wxAuth: true
                // hiddenNavTitle: true,
                // onLeftClick(router, route) {
                //     router.push({
                //         path: '/shop/list'
                //     })
                // }
            },
            component: () => import("@/views/shop/detail.vue"),
        },
        {
            path: "order",
            name: "shopOrder",
            meta: {
                title: "订单",
                hideHeaderNav: false,
                // hiddenNavTitle: true,
                onLeftClick(router, route) {
                    if (route.query.lastRedirect) {
                        const lr = decodeURIComponent(route.query.lastRedirect as string);
                        const u = getUrlObject(lr);
                        router.push({
                            path: u.url,
                            query: {
                                ...u.query
                            }

                        })
                    } else {
                        router.go(-1)
                    }
                }
            },
            component: () => import("@/views/shop/order.vue"),
        },
        {
            path: "orderList",
            name: "shopOrderList",
            meta: {
                title: "订单列表",
                hideHeaderNav: true,
                background: '#fff'
            },
            component: () => import("@/views/shop/orderList.vue"),
        },
        {
            path: "orderDetail",
            name: "shopOrderDetail",
            meta: {
                title: "订单详情",
                hideHeaderNav: true
            },
            component: () => import("@/views/shop/orderDetail.vue"),
        },
        {
            path: "pay",
            name: "shopPay",
            meta: {
                title: "支付",
                hideHeaderNav: true
            },
            component: () => import("@/views/shop/pay.vue"),
        },
        {
            path: "address",
            name: "shopAddress",
            meta: {
                title: "地址",
                hideHeaderNav: true
            },
            component: () => import("@/views/shop/address.vue"),
        },
        {
            path: "editAddress",
            name: "shopEditAddress",
            meta: {
                title: "编辑地址",
                hideHeaderNav: true
            },
            component: () => import("@/views/shop/editAddress.vue"),
        },
        {
            path: "express",
            name: "shopExpress",
            meta: {
                title: "物流信息",
                hideHeaderNav: true
            },
            component: () => import("@/views/shop/express.vue"),
        },
        {
            path: "paySuccess",
            name: "shopPaySuccess",
            meta: {
                title: "支付成功",
                hideHeaderNav: true
            },
            component: () => import("@/views/shop/paySuccess.vue"),
        },
        {
            path: "prize",
            name: "shopPrize",
            meta: {
                title: "",
                hideHeaderNav: true
            },
            component: () => import("@/views/shop/prize.vue"),
        },
    ],
};

export default routes;
