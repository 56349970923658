import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";

const routes: RouteRecordRaw = {
    path: "/promotion",
    redirect: "/promotion/index",
    component: MidRouterView,
    children: [
        {
            path: "index",
            name: "promotionIndex",
            meta: {
                title: "我的销售部",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/index.vue"),
        },
        {
            path: "order",
            name: "promotionOrder",
            meta: {
                title: "推广订单",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/order.vue"),
        },
        {
            path: "qrcode",
            name: "promotionQrcode",
            meta: {
                title: "推广邀请码",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/qrcode.vue"),
        },
        {
            path: "member",
            name: "promotionMember",
            meta: {
                title: "下线",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/member.vue"),
        },
        {
            path: "users",
            name: "promotionUsers",
            meta: {
                title: "我的用户",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/users.vue"),
        },
        {
            path: "network",
            name: "promotionNetwork",
            meta: {
                title: "我的销售网络",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/network.vue"),
        },
        {
            path: "manager",
            name: "promotionManager",
            meta: {
                title: "我的服务经理",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/manager.vue"),
        },
        {
            path: "bindSales",
            name: "promotionBindSales",
            meta: {
                title: "绑定销售员",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/bindSales.vue"),
        },
        {
            path: "notice",
            name: "promotionNotice",
            meta: {
                title: "消息列表",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/notice.vue"),
        },
        {
            path: "incomeDetail",
            name: "promotionIncomeDetail",
            meta: {
                title: "收益明细",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/incomeDetail.vue"),
        },
        {
            path: "salesCode",
            name: "promotionSalesCode",
            meta: {
                title: "领取分销码",
                hideHeaderNav: true
            },
            component: () => import("@/views/promotion/salesCode.vue"),
        },
        {
            path: "share",
            name: "promotionShare",
            meta: {
                title: "",
                hideHeaderNav: true,
                open: true,
            },
            component: () => import("@/views/promotion/share.vue"),
        },
        {
            path: "apply",
            name: "promotionApply",
            meta: {
                title: "申领实体卡",
                hideHeaderNav: true,
            },
            component: () => import("@/views/promotion/apply.vue"),
        },
        {
            path: "record",
            name: "promotionRecord",
            meta: {
                title: "申领记录",
                hideHeaderNav: true,
            },
            component: () => import("@/views/promotion/record.vue"),
        },
    ],
};

export default routes;
