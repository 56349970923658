import http from "@/utils/request";
import { HttpResultJson } from "@/types/http";

export function home(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion",
        method: "get",
        params
    });
}

export function order(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/order",
        method: "get",
        params
    });
}


export function member(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/member/subordinates",
        method: "get",
        params
    });
}


export function getQrcode(data?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/getQrcode",
        method: "post",
        data
    });
}

export function rule(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/commission/rule",
        method: "get",
        params
    });
}

// export function getQrcode(data?: any) {
//     return http.request<HttpResultJson>({
//         url: "/promotion/getQrcode",
//         method: "post",
//         data
//     });
// }

/**
 * 我的用户
 */
export function subordinates(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/member/subordinates",
        method: "get",
        params
    });
}

/**
 * 我的销售网络
 */
export function salesNetwork(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/member/salesNetwork",
        method: "get",
        params
    });
}

/**
 * 我的服务经理
 */
export function serviceManager(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/member/serviceManager",
        method: "get",
        params
    });
}


/**
 * 收益统计
 */
export function statistic(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/commission/statistic",
        method: "get",
        params
    });
}


/**
 * 近半年收益图表统计
 */
export function statisticChart(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/commission/statisticChart",
        method: "get",
        params
    });
}

/**
 * 收益明细列表
 */
export function commission(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/commission",
        method: "get",
        params
    });
}

/**
 * 获取消息列表
 */
export function notice(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/notice",
        method: "get",
        params
    });
}

/**
 * 领取说明
 */
export function instructions(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/instructions",
        method: "get",
        params
    });
}

/**
 * 绑定销售员
 * @param data 
 * @returns 
 */
export function bindSales(data?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/member/bindSales",
        method: "post",
        data
    });
}

/**
 * 通知已读
 * @param data 
 * @returns 
 */
export function noticeRead(data?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/notice/read",
        method: "post",
        data
    });
}

/**
 * 获取分销二维码链接
 */
export function getPoster(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/getPoster",
        method: "get",
        params
    });
}

/**
 * 绑定分销下级
 * @param data 
 * @returns 
 */
export function bindSub(data?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/associationInviteCode",
        method: "post",
        data
    });
}


/**
 * 申领实体卡
 * @param data
 * @returns
 */
export function applyRelicCard(data?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/applyRelicCard",
        method: "post",
        data
    });
}

/**
 * 申请记录
 */
export function applyRelicCardHistory(params?: any) {
    return http.request<HttpResultJson>({
        url: "/promotion/applyRelicCardHistory",
        method: "get",
        params
    });
}