import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";

const routes: RouteRecordRaw = {
  path: "/test",
  redirect: "/test/jssdk",
  component: MidRouterView,
  children: [
    {
      path: "jssdk",
      name: "testJssdk",
      meta: {
        title: "jssdk demo",
      },
      component: () => import("@/views/test/jssdk.vue"),
    },
    {
      path: "request",
      name: "testRequest",
      meta: {
        title: "request demo",
      },
      component: () => import("@/views/test/request.vue"),
    },
    {
      path: "touchShow",
      name: "touchShow",
      meta: {
        title: "touchShow demo",
      },
      component: () => import("@/views/test/touchShow.vue"),
    },
    {
      path: "swipeTouch",
      name: "swipeTouch",
      meta: {
        title: "swipeTouch",
        navRight: 'swipeTouch',
        open: true
      },
      component: () => import("@/views/test/swipeTouch.vue"),
    },
    {
      path: "f2",
      name: "f2",
      meta: {
        title: "f2",
        open: true
      },
      component: () => import("@/views/test/f2.vue"),
    },
    {
      path: "home",
      name: "testhome",
      meta: {
        title: "testhome",
        open: true
      },
      component: () => import("@/views/test/home.vue"),
    },
    {
      path: "mapDemo",
      name: "mapDemo",
      meta: {
        title: "mapDemo",
        open: true
      },
      component: () => import("@/views/test/mapDemo.vue"),
    },
    {
      path: "mp",
      name: "mp",
      meta: {
        title: "mp"
      },
      component: () => import("@/views/test/mp.vue"),
    },
    {
      path: "codefun/test1",
      name: "codefunTest1",
      meta: {
        title: "tpsd841_chanpinliebiao",
        open: true
      },
      component: () => import("@/views/test/codefun/test1.vue"),
    },
  ],
};

export default routes;
