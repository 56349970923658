import main from './Component.vue'
import { createApp, ComponentPublicInstance } from 'vue'



/**
 * ScreenLoad
 * @description 全屏loading插件化
 * @author maybe
 * @example 
 * 自定义AE-json动画, 传入对象为空展示默认动画
 * setup() {
 * const { proxy }= getCurrentInstance();
 *  proxy.$ScreenLoad.loading({
 *   animOptions: {
 *     animationData: animateJson,
 *    },
 * });
 * }
 */

export class ScreenLoad {
    instance: any;
    constructor(option: any = {}) {
        if (!this.instance) this.initInstance()

        const defaultOption: any = {
            text: '加载中...',
            // loadingBgColor: '#f5f5f5'
        }

        for (const key in defaultOption)
            this.instance[key] = option[key] || defaultOption[key]

        return this.instance
    }
    initInstance() {
        // 这里就是与 vue2 最大的区别了
        // 在 vue2 的时候，我们只需 instance.$mount() 便能得到节点，现在不行
        const app = createApp(main)
        // 需要一个容器
        const container = document.createElement('div')
        // 再进行挂载 - 挂载之后返回实例上下文
        this.instance = app.mount(container)

        document.body.appendChild(container)
    }
    loading() {
        this.instance.loading();
    }
    close() {
        this.instance.close();
    }
}

// const types = ['loading', 'close'];

// types.forEach(type => ScreenLoad[type] = (content: any) => ScreenLoad({ content, type }))

// 直接导出该方法
export default ScreenLoad;
