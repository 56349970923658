import { App } from "vue";

// axios
import axios from "axios";
// mitt 事件订阅中心
import mitt from "mitt";

import ScreenLoad from '@/components/ScreenLoad'

// 注册全局枚举
import * as Enum from "@/utils/enum";

export default {
  install: (app: App, options: any) => {
    const newIns = axios.create();
    app.config.globalProperties.$axios = newIns;
    app.config.globalProperties.$mitt = mitt();
    app.config.globalProperties.$ScreenLoad = new ScreenLoad();
    app.config.globalProperties.$Enum = Enum;
  },
};
