import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/map",
    redirect: "/map/index",
    component: MidRouterView,
    children: [
        {
            path: "index",
            name: "index",
            meta: {
                title: "定位",
                hideHeaderNav: true
            },
            component: () => import("@/views/map/location/index.vue"),
        },
        {
            path: "rail/show",
            name: "railShow",
            meta: {
                title: "围栏",
                hideHeaderNav: true
            },
            component: () => import("@/views/map/rail/show.vue"),
        },
        {
            path: "rail/index",
            name: "railIndex",
            meta: {
                title: "围栏列表",
                navRight: 'RightNavRight'
            },
            component: () => import("@/views/map/rail/index.vue"),
        },
        {
            path: "track",
            name: "track",
            meta: {
                title: "轨迹",
                hideHeaderNav: true
            },
            component: () => import("@/views/map/track.vue"),
        },
        {
            path: "setting",
            name: "setting",
            meta: {
                title: "定位设置",
                hideHeaderNav: true
            },
            component: () => import("@/views/map/location/setting.vue"),
        },
        {
            path: "location/mode",
            name: "locationMode",
            meta: {
                title: "定位模式设置",
                hideHeaderNav: true
            },
            component: () => import("@/views/map/location/mode.vue"),
        },
        {
            path: "location/desc",
            name: "locationDesc",
            meta: {
                title: "定位说明",
                hideHeaderNav: true
            },
            component: () => import("@/views/map/location/desc.vue"),
        },
        {
            path: "wifi",
            name: "wifi",
            meta: {
                title: "wifi设置",
                hideHeaderNav: true
            },
            component: () => import("@/views/map/wifi.vue"),
        },
    ],
};

export default routes;
