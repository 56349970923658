import { App } from "vue";
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import { createRouterGuards } from "./guards";
import routeList from "./routes";


const routes: Array<RouteRecordRaw> = [...routeList];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export const installRouter = (app: App) => {
  app.use(router);
  // 创建路由守卫
  createRouterGuards(router);
}

export default router;