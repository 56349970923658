import { App, computed, nextTick, ObjectDirective } from 'vue'

import auth from './auth';
import VTouchshow from '_directives/touchshow'

export default {
    install(app: App) {
        app.use(auth);
        app.use(VTouchshow);
    }
}