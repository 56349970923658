import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/guardian",
    redirect: "/guardian/list",
    component: MidRouterView,
    children: [
        {
            path: "list",
            name: "guardianList",
            meta: {
                title: "监护人",
                hideHeaderNav: true
            },
            component: () => import("@/views/guardian/index.vue"),
        },
        {
            path: "add",
            name: "addGuardian",
            meta: {
                title: "添加监护人",
                hideHeaderNav: true
            },
            component: () => import("@/views/guardian/add.vue"),
        },
    ],
};

export default routes;
