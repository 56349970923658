/**
 * 接口响应状态码枚举
 */
export enum ResponseCode {
    /**
     * 响应成功
     */
    success = 200,
    /**
     * 没有token
     */
    tokenNull = 40101,
    /**
    * token过期
     */
    tokenExpire = 40102,
    /**
     * token无效
     */
    tokenInvalid = 40103,
    /**
     * 无权限访问
     */
    noAuth = 40104,
}

