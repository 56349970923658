import { createApp } from 'vue'
import App from './App.vue'
import router, { installRouter } from "./router";
import store from './store'

// 重置样式
import '@/assets/css/reset.css';
// 注册全局组件
import components from "./utils/components";
// 注册全局插件
import plugins from "./utils/plugins";
// 注册全局指令
import directives from "./utils/directives";
// scss通用样式
import '@/assets/scss/common.scss'
// codefun
import '@/assets/css/codefun.css'

async function bootstrap() {

    const app = createApp(App);

    // 挂载状态管理(必须在路由注册前面)
    app.use(store);

    await installRouter(app);

    app.use(plugins)
        .use(components)
        .use(directives);

    // 路由准备就绪后挂载APP实例
    await router.isReady();

    app.mount('#app')

}

bootstrap();

console.log('---------------------Power by Maybe Email:maybe52052@gmail.com---------------------')
console.log('gitee: https://gitee.com/null_639_5368/vue-vant-base')

console.log('当前环境模式:', import.meta.env.MODE)