import { getSessionStore, setSessionStore, removeSessionStore } from '@/utils'


/**
 * 重定向
 */
const REDIRECT = "REDIRECT";


/**
 * 是否授权过微信登录
 */
const IS_WX_AUTH = "IS_WX_AUTH";
/**
 * 最后一个路由（微信授权完成后跳转页面(REDIRECT)后返回的页面）
 */
const LAST_REDIRECT = "LAST_REDIRECT";

/**
 * 是否隐藏底部tab-bar
 */
const IS_HIDE_FOOTER_TAB_BAR = "IS_HIDE_FOTTER_TAB_BAR"

export interface RedirectObject {
    /**
     * 跳转类型
     * route=当前项目路由  link=外部http(s)链接 
     */
    type: 'route' | 'link',
    /**
     * 路由或链接地址
     */
    url: string,
    /**
     * 附带的参数
     */
    query?: object,
    /**
     * 上一个页面（路由）链接
     */
    lastRedirect?: string
}

export function getRedirect() {
    const str = getSessionStore(REDIRECT);
    return str ? JSON.parse(str) as RedirectObject : null;
}

/**
 * 设置重定向缓存
 * @param  val 
 * @returns 
 */
export function setRedirect(val: RedirectObject) {
    if (!val) return;
    const str = JSON.stringify(val)
    return setSessionStore(REDIRECT, str);
}

export function removeRedirect() {
    return removeSessionStore(REDIRECT);
}


export function getIsWxAuth() {
    return getSessionStore(IS_WX_AUTH);
}

export function setIsWxAuth(val: any) {
    return setSessionStore(IS_WX_AUTH, val);
}

export function removeIsWxAuth() {
    return removeSessionStore(IS_WX_AUTH);
}


export function getLastRedirect() {
    const str = getSessionStore(LAST_REDIRECT);
    return str ? JSON.parse(str) as RedirectObject : null;
}

/**
 * 设置重定向缓存
 * @param  val 
 * @returns 
 */
export function setLastRedirect(val: RedirectObject) {
    if (!val) return;
    val.lastRedirect && (val.lastRedirect = encodeURIComponent(val.lastRedirect))
    const str = JSON.stringify(val)
    return setSessionStore(LAST_REDIRECT, str);
}

export function removeLastRedirect() {
    return removeSessionStore(LAST_REDIRECT);
}


export function getIsHideFooterTabBar() {
    const flag = getSessionStore(IS_HIDE_FOOTER_TAB_BAR) == 'true' ? true : false;
    return flag;
}

export function setIsHideFooterTabBar(val: any) {
    return setSessionStore(IS_HIDE_FOOTER_TAB_BAR, val);
}

export function remmoveIsHideFooterTabBar() {
    return removeSessionStore(IS_HIDE_FOOTER_TAB_BAR);
}

