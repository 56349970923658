<template>
  <div class="sl-loading-wrapper" v-if="loadingStatus">
    <div
      class="sl-loading-icon-wrapper"
      :class="[position]"
      :style="{ 'background-color': loadingBgColor }"
    >
      <vue-lottie
        :options="animOptions"
        @animCreated="animCreated"
        :width="'40vw'"
        :height="'40vw'"
      ></vue-lottie>
      <div class="sl-text" v-if="text">{{ text }}</div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, getCurrentInstance, reactive, toRefs } from "vue";
import VueLottie from "@/components/VueLottie.vue";
import { AnimationItem } from "lottie-web";
import json from "@/assets/json/loading.json";
export default defineComponent({
  name: "ScreenLoad",
  components: {
    VueLottie,
  },
  setup() {
    const state = reactive({
      position: "fixed",
      loadingBgColor: "#fff",
      loadingStatus: false,
      text: "",
      animOptions: {
        animationData: json,
      },
    });
    const animCreated = (anim: AnimationItem) => {};

    const loading = () => {
      state.loadingStatus = true;
    };
    const close = () => {
      state.loadingStatus = false;
    };

    return {
      ...toRefs(state),
      animCreated,
      close,
      loading,
    };
  },
});
</script>

<style lang="scss" scoped>
.sl-loading-wrapper {
  z-index: 1;
  position: relative;
  .sl-loading-icon-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .sl-text {
    font-size: 35px;
  }
  .fixed {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }
  .relative {
    position: relative;
  }
}
</style>