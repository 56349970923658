import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/",
    component: MidRouterView,
    children: [
        {
            path: "/login",
            name: "login",
            meta: {
                title: "登录",
                open: true,
            },
            component: () => import("@/views/entry/login.vue"),
        },
        {
            path: "/wxAuth",
            name: "wxAuth",
            meta: {
                title: "微信授权",
                open: true,
            },
            component: () => import("@/views/entry/wxAuth.vue"),
        },
        {
            path: "/register",
            name: "register",
            meta: {
                title: "注册",
                open: true,
            },
            component: () => import("@/views/entry/register.vue"),
        },
        {
            path: "/reset",
            name: "reset",
            meta: {
                title: "重置",
                open: true,
            },
            component: () => import("@/views/entry/reset.vue"),
        },
        {
            path: "/bind",
            name: "bind",
            meta: {
                title: "绑定",
                open: false,
            },
            component: () => import("@/views/entry/bind.vue"),
        },
        {
            path: "/completeInfo",
            name: "completeInfo",
            meta: {
                title: "完善信息",
                open: false,
            },
            component: () => import("@/views/entry/completeInfo.vue"),
        },
    ],
};

export default routes;
