import { createRouter, createWebHashHistory, RouteMeta, RouteRecordRaw } from "vue-router";

import MidRouterView from '@/components/MidRouterView.vue'
// layout
import Footer from "@/views/layout/footer/index.vue";
import Header from "@/views/layout/header/index.vue";


const devModules: string[] = []

//#region 自动导入 modules (vite)
// 主要模块
const mainModules: RouteRecordRaw[] = [

];
// 扩展模块
const extraModules: RouteRecordRaw[] = [

];
// 这里要用globEager 同步获取
const file: any = import.meta.glob('./modules/*.ts', { eager: true })
const paths = Object.keys(file);
paths.forEach(path => {

  // 生产模式不加载开发模块
  if (import.meta.env.PROD && devModules.find(name => path.includes(name))) {
    return;
  }

  const module = file[path].default;
  // 入口模块
  if (path.includes('entry')) {
    extraModules.push(module)
    return;
  }
  mainModules.push(module);
})
// console.log(modules)
//#endregion

/** 
 * 路由配置
 * @param meta  扩展meta => src/types/route-meta.d.ts
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "fotterMain",
    redirect: "/home",
    component: Footer,
    children: [
      {
        path: "home",
        name: "home",
        meta: {
          title: "消息",
        },
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "phone",
        name: "phone",
        meta: {
          title: "沟通"
        },
        component: () => import("@/views/contact/phone.vue")
      },
      {
        path: "location",
        name: "location",
        meta: {
          title: "定位",
        },
        component: () => import("@/views/map/location/index.vue"),
      },
      {
        path: "personCenter",
        name: "personCenter",
        meta: {
          title: "我的",
        },
        component: () => import("@/views/personCenter/index.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "mainModules",
    redirect: "/home",
    component: Header,
    children: [...mainModules],
  },
  {
    path: "/",
    name: "extraModules",
    component: MidRouterView,
    children: [...extraModules],
  },
  {
    path: "/redirect",
    name: "redirect",
    meta: {
      title: "Redirect demo",
      hideHeader: true,
      open: true,
    },
    component: () => import("@/views/redirect/index.vue"),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () =>
      import('@/views/404.vue')
  },
];

export default routes;
