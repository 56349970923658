<template>
  <vp-container :title="getTitle" :hideNav="$route.meta.hideHeaderNav" :showNavLeft="!$route.meta.hideNavLeftBtn"
    :class="`theme-${$route.meta.theme}`" :background="$route.meta.background">
    <template #headLeft>
      <a @click.stop="clickLeft">
        <van-icon name="arrow-left" size="4vw"></van-icon>
      </a>
    </template>
    <template v-if="$route.meta.navRight" #headRight>
      <component :is="$route.meta.navRight"></component>
    </template>
    <router-view></router-view>
  </vp-container>
</template>

<script lang="ts">
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
} from "vue";
import { Container } from "@/components/Vp";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import { computed } from "vue";

export default defineComponent({
  props: {},
  components: {
    [Container.name]: Container,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();


    const clickLeft = () => {
      if (route.meta.onLeftClick) {
        route.meta.onLeftClick(router, route);
        return;
      }
      router.back();
    };

    const getTitle = computed(() => {

      return route.meta.title && !route.meta.hiddenNavTitle ? route.meta.title : ''

    })
    onMounted(() => {
    })
    return {
      clickLeft,
      getTitle
    };
  },
});
</script>

<style scoped lang="scss">
.theme-default {
  // ....
}

.theme-blue {
  :deep(.van-nav-bar) {
    background-color: #377ffc;

    // 去除vant-nav-bar下边框横线
    &::after {
      border-bottom-width: 0px;
    }

    .van-nav-bar__left,
    .van-nav-bar__left i,
    .van-nav-bar__right,
    .van-nav-bar__right i,
    .van-nav-bar__title {
      color: #fff;
    }
  }
}
</style>
