import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";

const routes: RouteRecordRaw = {
  path: "/equipment",
  redirect: "/equipment/index",
  component: MidRouterView,
  children: [
    {
      path: "index",
      name: "equipmentManage",
      meta: {
        title: "设备管理",
        hideHeaderNav: true
      },
      component: () => import("@/views/equipmentManage/index.vue"),
    },
    {
      path: "member",
      name: "memberManagement",
      meta: {
        title: "成员列表",
        hideHeaderNav: true,
      },
      component: () => import("@/views/equipmentManage/member.vue"),
    },
    {
      path: "editMember",
      name: "editMember",
      meta: {
        title: "成员编辑",
        hideHeaderNav: true
      },
      component: () => import("@/views/equipmentManage/editMember.vue"),
    },
    {
      path: "equipmentSettings",
      name: "equipmentSettings",
      meta: {
        title: "设备设置",
        hideHeaderNav: true
      },
      component: () => import("@/views/equipmentManage/equipment.vue"),
    },
    {
      path: "clock",
      name: "clockSettings",
      meta: {
        title: "闹钟设置",
        hideHeaderNav: true,
      },
      component: () => import("@/views/equipmentManage/clock.vue"),
    },
    {
      path: "noDisturbing",
      name: "noDisturbing",
      meta: {
        title: "免打扰设置",
        hideHeaderNav: true,
      },
      component: () => import("@/views/equipmentManage/noDisturbing.vue"),
    },
    {
      path: "timingSwitcher",
      name: "timingSwitcher",
      meta: {
        title: "定时开关机",
        hideHeaderNav: true
      },
      component: () => import("@/views/equipmentManage/timingSwitcher.vue"),
    },
    {
      path: "whitelist",
      name: "contactWhitelist",
      meta: {
        title: "亲情号码",
        hideHeaderNav: true,
      },
      component: () => import("@/views/contact/whitelist.vue"),
    },
    {
      path: "aboutMachine",
      name: "aboutMachine",
      meta: {
        title: "关于本机",
        hideHeaderNav: true
      },
      component: () => import("@/views/equipmentManage/aboutMachine.vue"),
    },
  ],
};

export default routes;
