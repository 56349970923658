import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
  path: "/message",
  redirect: "/message/family",
  component: MidRouterView,
  children: [
    {
      path: "family",
      name: "messageFamily",
      meta: {
        title: "亲情号码",
        hideHeaderNav: true
      },
      component: () => import("@/views/message/family.vue"),
    },
    {
      path: "bodyTemperature",
      name: "messageBodyTemperature",
      meta: {
        title: "体温监测",
        hideHeaderNav: true
      },
      component: () => import("@/views/message/bodyTemperature.vue"),
    },
    {
      path: "rail",
      name: "messageRail",
      meta: {
        title: "告警提醒",
        hideHeaderNav: true
      },
      component: () => import("@/views/message/rail.vue"),
    },
    {
      path: "electricQuantity",
      name: "messageElectricQuantity",
      meta: {
        title: "电量提醒",
        hideHeaderNav: true
      },
      component: () => import("@/views/message/electricQuantity.vue"),
    },
    {
      path: "callingPlan",
      name: "messageCallingPlan",
      meta: {
        title: "话费套餐提醒",
        hideHeaderNav: true
      },
      component: () => import("@/views/message/callingPlan.vue"),
    },
    {
      path: "callRecords",
      name: "messageCallRecords",
      meta: {
        title: "通话记录",
        hideHeaderNav: true
      },
      component: () => import("@/views/message/callRecords.vue"),
    },
    {
      path: "alarm",
      name: "messageAlarm",
      meta: {
        title: "告警提醒",
        hideHeaderNav: true
      },
      component: () => import("@/views/message/alarm.vue"),
    },
    {
      path: "textMessage",
      name: "textMessage",
      meta: {
        title: "短信列表",
        hideHeaderNav: true
      },
      component: () => import("@/views/message/textMessage.vue"),
    },
  ],
};

export default routes;
