import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/wallet",
    redirect: "/wallet/index",
    component: MidRouterView,
    children: [
        {
            path: "index",
            name: "walletIndex",
            meta: {
                title: "我的钱包",
                hideHeaderNav: true
            },
            component: () => import("@/views/wallet/index.vue"),
        },
        {
            path: "record",
            name: "walletRecord",
            meta: {
                title: "余额明细",
                hideHeaderNav: true
            },
            component: () => import("@/views/wallet/record.vue"),
        },
        {
            path: "detail",
            name: "walletDetail",
            meta: {
                title: "详情",
                hideHeaderNav: true,
                background: '#fff'
            },
            component: () => import("@/views/wallet/detail.vue"),
        }
    ],
};

export default routes;
