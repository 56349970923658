// src/uitls/components.ts

import { App } from "vue";

// Vant UI
import {
  ConfigProvider,
  Button,
  NavBar,
  Cell,
  CellGroup,
  Form,
  Field,
  Tabbar,
  TabbarItem,
  Grid,
  GridItem,
  Image,
  Icon,
  Row,
  Col,
  Loading,
  Checkbox,
  CheckboxGroup,
  Space,
  Picker,
  Popup,
  ActionSheet,
  Switch,
  Radio,
  RadioGroup,
  Tag,
  Badge 
} from "vant";
// import 'vant/lib/index.css' // 默认主题
// import '@/assets/less/vant.less' // 定制vant主题(官方不推荐了)


// 导航组件全局注册
import { Container as VpContainer } from "@/components/Vp";

// 自定义组件
import MPanel from "@/components/MPanel.vue";
import SvgIcon from "@/components/SvgIcon.vue";

// 导航栏所需动态组件
import ListNavRight from '@/views/article/ListNavRight.vue'
import RightNavRight from '@/views/map/rail/RightNavRight.vue'

// tailwindcss
import '@/assets/css/index.css'
// iconfont
import '@/assets/font/iconfont/iconfont.css'
// 定制vant主题
import '@/assets/css/vant.css'

import VueLuckyCanvas from '@lucky-canvas/vue'
import EmptyBlock from '@/components/EmptyBlock.vue'

export default {
  install: (app: App, options: any) => {
    // app.use(Vant)
    app.use(ConfigProvider)
      .use(Button)
      .use(NavBar)
      .use(Cell)
      .use(CellGroup)
      .use(Tabbar)
      .use(Form)
      .use(Field)
      .use(TabbarItem)
      .use(Grid)
      .use(GridItem)
      .use(Image)
      .use(Icon)
      .use(Row)
      .use(Col)
      .use(Loading)
      .use(Checkbox)
      .use(CheckboxGroup)
      .use(Space)
      .use(Picker)
      .use(Popup)
      .use(ActionSheet)
      .use(Switch)
      .use(Radio).use(RadioGroup)
      .use(Tag)
      .use(VueLuckyCanvas)
      .use(Badge)

    app.component(VpContainer.name, VpContainer)

    app.component(MPanel.name, MPanel)
      .component(SvgIcon.name, SvgIcon)
      .component(EmptyBlock.name, EmptyBlock);

    // 注册导航栏所需动态组件
    app.component(ListNavRight.name, ListNavRight)
    app.component(RightNavRight.name, RightNavRight)

  },
};
