<template>
  <div class="footer-wrapper">
    <div class="footer-view" :class="[`footer-view--${isHideFooterTabBar ? 'none' : 'bar'}`]">
      <router-view></router-view>
    </div>
    <van-tabbar v-if="!isHideFooterTabBar" inactive-color="#D1D1D6
    " class="my-tabbar" route v-model="state.active" safe-area-inset-bottom>
      <van-tabbar-item replace to="/home">
        <span>消息</span>
        <template #icon="props">
          <van-icon class-prefix="iconfont" name="xiaoxi" size="5vw"></van-icon>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="phone" replace to="/phone">
        <span>沟通</span>
        <template #icon="props">
          <van-icon class-prefix="iconfont" name="dianhua1" size="5vw"></van-icon>
        </template>

      </van-tabbar-item>
      <van-tabbar-item icon="location" replace to="/location">
        <span>定位</span>
        <template #icon="props">
          <van-icon class-prefix="iconfont" name="dingwei1" size="5vw"></van-icon>
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/personCenter">
        <span>我的</span>
        <template #icon="props">
          <van-icon class-prefix="iconfont" name="wode" size="5vw"></van-icon>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script setup lang="ts">
import { getIsHideFooterTabBar } from "@/utils/session";
import { reactive, computed } from "vue";

const state = reactive({
  active: 0,
});

const isHideFooterTabBar = getIsHideFooterTabBar();




</script>

<style scoped lang="scss">
.footer-view {
  overflow: auto;
}

.footer-view--bar {
  height: calc(100vh - 100px - env(safe-area-inset-bottom));
}

.footer-view--none {
  height: 100vh;
}


.my-tabbar {
  z-index: 99 !important;
}
</style>


