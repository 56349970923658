import { defineStore } from "pinia";

interface IConfigState {
    debug: boolean
}

export const useConfigStore = defineStore('config', {
    state: (): IConfigState => ({
        debug: false
    }),
    actions: {
        setDebug(data: boolean) {
            this.debug = data;
        }
    },
})
