<template>
    <div class="empty-block">
        {{ props.text }}
    </div>
</template>

<script lang="ts" setup name="EmptyBlock">
import { onMounted, reactive } from 'vue'


const props = defineProps({
    text: {
        type: String,
        default: '暂无数据'
    }
})

const emit = defineEmits([])

const state = reactive({
})



onMounted(async () => {

})
</script>
<style lang="scss" scoped>
.empty-block {
    height: 150px;
    line-height: 150px;
    text-align: center;
}
</style>