import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MidRouterView from "@/components/MidRouterView.vue";


const routes: RouteRecordRaw = {
    path: "/activity",
    redirect: "/activity/list",
    component: MidRouterView,
    children: [
        {
            path: "list",
            name: "activityList",
            meta: {
                title: "活动列表",
                hideHeaderNav: true
            },
            component: () => import("@/views/activity/list.vue"),
        },
        {
            path: "detail",
            name: "activityDetail",
            meta: {
                title: "活动详情",
                hideHeaderNav: true
            },
            component: () => import("@/views/activity/detail.vue"),
        },
    ],
};

export default routes;
