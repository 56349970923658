import { defineStore } from "pinia";
import {
  getToken,
  setToken,
  removeToken,
  setUserInfo,
  removeUserInfo,
  getUserInfo,
} from "@/utils/auth";
import * as userApi from "@/api/user";

interface IUserInfo {
  name: string,
  avatar: string,
  type: number
}
interface IUserState {
  isInitUserInfo: boolean;
  token: string;
  userinfo: IUserInfo;
}

export const useUserStore = defineStore('user', {
  state: (): IUserState => ({
    isInitUserInfo: false,
    token: "",
    userinfo: {
      name: '',
      avatar: '',
      type: 0
    },
  }),
  actions: {
    async login(data: any) {
      let result = await userApi.login(data);
      this.token = result.data.token;
      setToken(result.data.token);
    },
    async initUserInfo() {
      let result = await userApi.info();
      this.userinfo = result.data as any;
      this.isInitUserInfo = true;
    },
    async logout() {
      this.userinfo = {
        name: '',
        avatar: '',
        type: 0
      };
      this.token = '';
      this.isInitUserInfo = false;
      removeToken();
      removeUserInfo();
    }
  },
  getters: {
    getIsInitUserInfo(): boolean {
      return this.isInitUserInfo;
    },
    getToken(): string {
      return this.token;
    },
    getUserInfo(): IUserInfo {
      return this.userinfo;
    },
  }
})
